import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

Vue.use(Toast, {});

import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

Vue.filter("join", function (value) {
	if (!value) {
		return "";
	}

	if (typeof value !== "object") {
		return value;
	}

	return value.join(", ");
});

new Vue({
	render: h => h(App)
}).$mount("#app");
