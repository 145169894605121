<template>
	<div id="app">
		<Home />
		<About />
		<!--<Portfolio />-->
		<Contact />
	</div>
</template>

<script>
	import Home from "./pages/Home.vue";
	import Contact from "./pages/Contact.vue";
	import About from "./pages/About.vue";
	// import Portfolio from "./pages/Portfolio.vue";

	import { PAGE_TITLE } from "./config";

	export default {
		name: "App",
		components: {
			Home,
			Contact,
			About
			// Portfolio
		},
		created() {
			document.title = PAGE_TITLE || "No title";
		}
	};
</script>
