<template>
	<div class="section about pp-scrollable">
		<!-- Title -->
		<section class="title-section text-left text-sm-center revealator-slideup revealator-once revealator-delay1">
			<h1>Mon <span>parcours</span></h1>
			<span class="title-bg">Histoire</span>
		</section>

		<section class="main-content revealator-slideup revealator-once revealator-delay1">
			<div class="container">
				<div class="row">
					<!-- Personal Info Starts -->
					<div class="col-12 col-lg-12 col-xl-12 text-center">
						<div class="row">
							<div class="col-12">
								<h3 class="text-uppercase custom-title mb-0 ft-wt-600">Informations</h3>
							</div>
							<div class="col-12 d-block d-sm-none">
								<img
									src="https://mehbi.com/assets/img/zakaria_mehbi.jpg"
									class="img-fluid main-img-mobile"
									alt="Zakaria Mehbi"
								/>
							</div>
							<div class="col-6">
								<ul class="about-list list-unstyled open-sans-font">
									<li>
										<span class="title">Prénom : </span>
										<span class="value d-block d-sm-inline-block">
											{{ firstname }}
										</span>
									</li>

									<li>
										<span class="title">Nom : </span>
										<span class="value d-block d-sm-inline-block">
											{{ lastname }}
										</span>
									</li>

									<li>
										<span class="title">Langues : </span>
										<span class="value d-block d-sm-inline-block">
											{{ languages | join }}
										</span>
									</li>
								</ul>
							</div>
							<div class="col-6">
								<ul class="about-list list-unstyled open-sans-font">
									<li>
										<span class="title">Email : </span>
										<span class="value d-block d-sm-inline-block">
											{{ email }}
										</span>
									</li>
									<li>
										<span class="title">Pays : </span>
										<span class="value d-block d-sm-inline-block">
											{{ country }}
										</span>
									</li>
									<li>
										<span class="title">Ville : </span>
										<span class="value d-block d-sm-inline-block">
											{{ city }}
										</span>
									</li>
								</ul>
							</div>
							<!--
							<div class="col-12 mt-3">
								<a class="button" :href="resume">
									<span class="button-text">Télécharger mon CV</span>
									<span class="button-icon">
										<span class="fas fa-download"></span>
									</span>
								</a>
							</div>
							-->
						</div>
					</div>

					<!-- Boxes Starts -->
					<!--
					<div class="col-12 col-lg-7 col-xl-6 mt-5 mt-lg-0">
						<div class="row">
							<div class="col-6">
								<div class="box-stats with-margin">
									<h3 class="poppins-font position-relative">{{ yearsOfExperience }}</h3>
									<p class="open-sans-font m-0 position-relative text-uppercase">
										années <span class="d-block">d'expérience</span>
									</p>
								</div>
							</div>
							<div class="col-6">
								<div class="box-stats with-margin">
									<h3 class="poppins-font position-relative">{{ completedProjects }}</h3>
									<p class="open-sans-font m-0 position-relative text-uppercase">
										projets <span class="d-block">complétés</span>
									</p>
								</div>
							</div>
							<div class="col-6">
								<div class="box-stats">
									<h3 class="poppins-font position-relative">{{ happyCustomers }}</h3>
									<p class="open-sans-font m-0 position-relative text-uppercase">
										clients <span class="d-block">satisfés</span>
									</p>
								</div>
							</div>
							<div class="col-6">
								<div class="box-stats">
									<h3 class="poppins-font position-relative">100%</h3>
									<p class="open-sans-font m-0 position-relative text-uppercase">
										taux de <span class="d-block">réponse</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					-->
				</div>

				<hr class="separator" />

				<!-- Experience & Education Starts -->
				<div class="row">
					<div class="col-12">
						<h3 class="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
							Expérience <span>et</span> Éducation
						</h3>
					</div>
					<div class="col-lg-12 m-15px-tb text-left text-sm-center">
						<div class="resume-box">
							<ul v-for="({ year, title, company, description }, index) of experiences" :key="index">
								<li>
									<div class="icon">
										<i class="fas fa-briefcase"></i>
									</div>
									<span class="time open-sans-font text-uppercase">{{ year }}</span>
									<h5 class="poppins-font text-uppercase">
										{{ title }}
										<span class="place open-sans-font">{{ company }}</span>
									</h5>
									<p class="open-sans-font" v-html="description"></p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import { FREELANCE, EXPERIENCES, LINKS } from "../config";

	const {
		name,
		firstname,
		lastname,
		email,
		phone,
		country,
		city,
		languages,
		programming,
		yearsOfExperience,
		completedProjects,
		happyCustomers
	} = FREELANCE;

	const { resume } = LINKS;

	export default {
		data() {
			return {
				name,
				firstname,
				lastname,
				email,
				phone,
				country,
				city,
				languages,
				programming,
				yearsOfExperience,
				completedProjects,
				happyCustomers,
				resume,
				experiences: EXPERIENCES
			};
		}
	};
</script>

<style></style>
