export const PAGE_TITLE = "Zakaria Mehbi - CTO & Développeur Web";

export const FREELANCE = {
	name: "Zakaria Mehbi",
	title: "CTO & Développeur Web",
	description:
		"Développeur Web FullStack spécialisé dans les technologies JavaScript sur l’ensemble de la pile MEVN (MongoDB, Express, Vue.js, Node.js). Expérience dans la création d'application web et mobile avec des systèmes d'API backend (REST & GraphQL) performant.",
	firstname: "Zakaria",
	lastname: "Mehbi",
	email: "zakaria@mehbi.com",
	phone: "",
	country: "France",
	city: "Lyon / Grenoble",
	languages: ["Français", "Anglais"],
	yearsOfExperience: "7",
	completedProjects: "32",
	happyCustomers: "21"
};

export const EXPERIENCES = [
	{
		year: "2023 – Aujourd’hui",
		title: "Founder & CEO",
		company: "InsertChat",
		description: ""
	},
	{
		year: "2022 – Aujourd’hui",
		title: "Founder & CEO",
		company: "HeyLoc",
		description: ""
	},
	{
		year: "2020 – 2022",
		title: "Chief Technology Officer (CTO)",
		company: "Sinao",
		description: ""
	},
	{
		year: "2019 – 2020",
		title: "Lead développeur",
		company: "ARLynk",
		description: ""
	},
	{
		year: "2012 – 2023",
		title: "Founder & CEO",
		company: "DozenOfElites",
		description: ""
	},
	{
		year: "2017 – 2019",
		title: "Master en administration des affaires",
		company: "Université Nice Sophia Antipolis",
		description: ""
	},
	{
		year: "2014 - 2019",
		title: "Diplôme d'ingénieur Informatique",
		company: "Ecole Marocaine des Sciences de l'ingénieur",
		description: ""
	}
];

export const PORTFOLIO = [
	{ title: "", url: "" },
	{ title: "", url: "" },
	{ title: "", url: "" }
];

export const LINKS = {
	github: "https://github.com/zakariamehbi",
	linkedin: "https://www.linkedin.com/in/zakariamehbi/",
	malt: "https://www.malt.fr/profile/zakariamehbi",
	producthunt: "https://www.producthunt.com/@zakaria_mehbi",
	twitter: "https://twitter.com/zakariamehbi",
	facebook: "",
	instagram: "",
	resume: ""
};

export const SEND_EMAIL_URL = "https://send-email.zakariamehbi.workers.dev";
