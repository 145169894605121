<template>
	<div class="section home pp-scrollable">
		<section
			class="container-fluid main-container container-home p-0 revealator-slideup revealator-once revealator-delay1"
		>
			<div class="color-block d-none d-lg-block"></div>
			<div class="row home-details-container align-items-center">
				<div class="col-lg-4 bg position-fixed d-none d-lg-block"></div>
				<div class="col-12 col-lg-8 offset-lg-4 home-details text-left text-sm-center text-lg-left">
					<div>
						<img
							src="https://mehbi.com/assets/img/zakaria_mehbi.jpg"
							class="img-fluid main-img-mobile d-none d-sm-block d-lg-none"
							alt="Zakaria Mehbi"
						/>
						<h1 class="text-uppercase poppins-font">
							{{ name }}.<span>{{ title }}</span>
						</h1>
						<p class="open-sans-font">
							{{ description }}
						</p>
						<a class="button" href="#about">
							<span class="button-text">Mon parcours</span>
							<span class="button-icon">
								<span class="fas fa-arrow-right"></span>
							</span>
						</a>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import { FREELANCE } from "../config";

	const { name, title, description } = FREELANCE;

	export default {
		data() {
			return {
				name,
				title,
				description
			};
		}
	};
</script>

<style></style>
