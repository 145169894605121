<template>
	<div class="section contact pp-scrollable">
		<!--  Title -->
		<section class="title-section text-left text-sm-center revealator-slideup revealator-once revealator-delay1">
			<h1>get in <span>touch</span></h1>
			<span class="title-bg">contact</span>
		</section>

		<!-- Content -->
		<section class="main-content revealator-slideup revealator-once revealator-delay1">
			<div class="container">
				<div class="row">
					<!-- Left Side Starts -->
					<div class="col-12 col-lg-4">
						<h3 class="text-uppercase custom-title mb-0 ft-wt-600 pb-3">Don't be shy !</h3>
						<p class="open-sans-font mb-3">
							N'hésitez pas à me contacter. Je suis toujours ouvert à discuter de nouveaux projets,
							d'idées ou d'opportunités.
						</p>
						<p class="open-sans-font custom-span-contact position-relative">
							<i class="fa fa-envelope-open position-absolute"></i>
							<span class="d-block">mail me</span>{{ email }}
						</p>
						<!--
						<p class="open-sans-font custom-span-contact position-relative">
							<i class="fa fa-phone-square position-absolute"></i>
							<span class="fa fa-phone-square position-absolute"></span>
							<span class="d-block">call me</span>{{ phone }}
						</p>
						-->
						<ul class="social list-unstyled pt-1 mb-5">
							<li v-if="github" class="github">
								<a target="blank" title="github" :href="github"><i class="fab fa-github"></i></a>
							</li>
							<li v-if="linkedin" class="linkedin">
								<a target="blank" title="linkedin" :href="linkedin"><i class="fab fa-linkedin"></i></a>
							</li>
							<li v-if="malt" class="malt">
								<a target="blank" title="malt" :href="malt">
									<img style="padding-bottom: 3px; padding-left: 0.062rem" src="/assets/malt.svg" />
								</a>
							</li>
							<li v-if="twitter" class="twitter">
								<a target="blank" title="twitter" :href="twitter"><i class="fab fa-twitter"></i></a>
							</li>
							<li v-if="facebook" class="facebook">
								<a target="blank" title="facebook" :href="facebook"><i class="fab fa-facebook"></i></a>
							</li>
							<li v-if="instagram" class="instagram">
								<a target="blank" title="instagram" :href="instagram"
									><i class="fab fa-instagram"></i
								></a>
							</li>
						</ul>
					</div>

					<!-- Contact Form Starts -->
					<div class="col-12 col-lg-8">
						<form class="contactform" method="post" @submit.prevent="onSubmit">
							<div class="contactform">
								<div class="row">
									<div class="col-12 col-md-4">
										<input
											v-model="form.lastname"
											type="text"
											name="name"
											placeholder="VOTRE NOM"
											required
										/>
									</div>
									<div class="col-12 col-md-4">
										<input
											v-model="form.email"
											type="email"
											name="email"
											placeholder="VOTRE EMAIL"
											required
										/>
									</div>
									<div class="col-12 col-md-4">
										<input
											v-model="form.subject"
											type="text"
											name="subject"
											placeholder="VOTRE SUJET"
											required
										/>
									</div>
									<div class="col-12">
										<textarea
											v-model="form.message"
											name="message"
											placeholder="VOTRE MESSAGE"
										></textarea>
										<button type="submit" class="button">
											<span class="button-text">Envoyer</span>
											<span class="button-icon">
												<span class="fas fa-paper-plane"></span>
											</span>
										</button>
									</div>
									<div class="col-12 form-message">
										<span class="output_message text-center font-weight-600 text-uppercase"></span>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import axios from "axios";
	import { FREELANCE, LINKS, SEND_EMAIL_URL } from "../config";

	const { email, phone } = FREELANCE;
	const { github, linkedin, malt, producthunt, twitter, facebook, instagram } = LINKS;

	export default {
		data() {
			return {
				email,
				phone,
				github,
				linkedin,
				malt,
				producthunt,
				twitter,
				facebook,
				instagram,
				form: {
					lastname: null,
					email: null,
					subject: null,
					message: null
				}
			};
		},
		methods: {
			async onSubmit() {
				const { lastname, email, subject, message } = this.form;

				try {
					if (!lastname || !email || !subject || !message) {
						return this.$toast.error(`Tous les champs sont obligatoires.`, {
							position: "bottom-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: "button",
							icon: true,
							rtl: false
						});
					}

					await axios
						.post(SEND_EMAIL_URL, {
							lastname,
							email,
							subject,
							message
						})
						.then(response => {
							this.form = {
								lastname: null,
								email: null,
								subject: null,
								message: null
							};

							this.$toast.success("Hello, j'ai bien reçu ton e-mail, je te répondrai rapidement!", {
								position: "bottom-right",
								timeout: 5000,
								closeOnClick: true,
								pauseOnFocusLoss: true,
								pauseOnHover: true,
								draggable: true,
								draggablePercent: 0.6,
								showCloseButtonOnHover: false,
								hideProgressBar: true,
								closeButton: "button",
								icon: true,
								rtl: false
							});
						})
						.catch(error => {
							console.error(error);

							this.$toast.error(
								`Je n'ai pas pu recevoir ton e-mail, essaye de me contacter directement sur ${email}.`,
								{
									position: "bottom-right",
									timeout: 5000,
									closeOnClick: true,
									pauseOnFocusLoss: true,
									pauseOnHover: true,
									draggable: true,
									draggablePercent: 0.6,
									showCloseButtonOnHover: false,
									hideProgressBar: true,
									closeButton: "button",
									icon: true,
									rtl: false
								}
							);
						});
				} catch (error) {
					console.error(error);
				}
			}
		}
	};
</script>

<style></style>
